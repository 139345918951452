import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'account', label: 'Account', alignRight: false },
  { id: 'action', label: 'Action', alignCenter: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log(array, 'abdullah ki gand maro');
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const token = localStorage.getItem('accessToken');

  const user = useCallback(() => {
    try {
      setLoader(true);
      const config = {
        method: 'get',
        url: 'https://sarealtvapi.developer-iu.xyz/api/list/client',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      axios(config)
        .then((response) => {
          setLoader(false);
          setUSERLIST(response.data.clientList.data);
          console.log(response.data.clientList.data, 'user api');
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error, 'user ');
    }
  }, [token]);

  useEffect(() => {
    user();
  }, [user]);

  const [, setMovie] = useState();
  const delet = (id) => {
    setLoader(true);
    try {
      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/remove/client/profile/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios(config)
            .then((response) => {
              setLoader(false);
              setMovie(response.data);
              console.log(response, 'user deleted');
              Swal.fire({
                title: 'Deleted!',
                text: 'User has been deleted.',
                icon: 'success',
              });
              window.location.reload();
            })
            .catch((error) => {
              console.log(error, 'user delete api error');
              Swal.fire({
                title: error.response.data.message || 'Error',
                text: error?.message,
                icon: 'error',
                button: 'Ok',
              });
            });
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
          console.log(error, ' user error');
          Swal.fire({
            title: error.response.data.message,
            text: error?.message,
            icon: 'danger',
            button: 'Ok',
          });
    }
  };
  //   setLoader(true);
  //   try {
  //     const config = {
  //       method: 'POST',
  //       url: `https://sarealtvapi.developer-iu.xyz/api/remove/client/profile/${id}`,
  //       headers: {
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${JSON.parse(token)}`,
  //       },
  //     };
  //     axios(config)
  //       .then((response) => {
  //         setMovie(response.data);
  //         console.log(response, 'user =======');
  //         setLoader(false);
  //           window.location.reload();
  //           if (response?.data.status === true) {
  //           Swal.fire({
  //             title: "Are you sure?",
  //             text: "You won't be able to revert this!",
  //             icon: "warning",
  //             showCancelButton: true,
  //             confirmButtonColor: "#3085d6",
  //             cancelButtonColor: "#d33",
  //             confirmButtonText: "Yes, delete it!"
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               Swal.fire({
  //                 title: "Deleted!",
  //                 text: "Your file has been deleted.",
  //                 icon: "success"
  //               });
  //             }
  //           });
  //         } else {
  //           setLoader(false);
  //         }
  //       }).catch((error) => {
  //         console.log(error, "user delete api")
  //         Swal.fire({
  //           title: error.response.data.message,
  //           text: error?.message,
  //           icon: 'danger',
  //           button: 'Ok',
  //         });
  //       })
  //   } catch (error) {
  //     setLoader(false);
  //     console.log(error, ' user error');
  //     Swal.fire({
  //       title: error.response.data.message,
  //       text: error?.message,
  //       icon: 'danger',
  //       button: 'Ok',
  //     });
  //   }
  // };

  return (
    <>
      <Helmet>
        <title> User | SAREALTV </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          {/* <Button variant="contained" sx={{ backgroundColor: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, backgroundColor: 'white' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // console.log(row, 'abdullah nay gande marwa li');
                    const { id, name, email, picture, gender, account_type: acountType } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {id}
                          </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={picture} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{gender}</TableCell>
                        <TableCell align="left">{acountType}</TableCell>
                        <TableCell style={{ width: '30%', display: 'flex' }}>
                          <MenuItem sx={{ color: 'error.main' }} onClick={() => delet(id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{}} />
                          </MenuItem>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
