import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageUploading from 'react-images-uploading';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormLabel } from '@mui/material';


const EditEpisode = () => {

  const [searchParams] = useSearchParams();
  const seasonId = searchParams.get('season');
  const token = localStorage.getItem('accessToken');
  const { id } = useParams();
  const { seasonNo } = useParams();

  const [name, setName] = useState('');
  const [files, setFiles] = useState();
  const [meta, setMeta] = useState();
  // specify upload params and url for your files
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(status, 'status');
    setMeta(meta, 'meta');
    setFiles(file, 'file');
  };

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList, addUpdateIndex);
    setImage(imageList[0].file);
  };

  // Movie upload work

  const [loader, setLoader] = useState(false);


  const [option, setOptions] = useState([]);
  const [catvalue, setCatValue] = useState();
  const [title, setTitle] = useState();
  const [subDesc, setSubDesc] = useState();
  const [desc, setDesc] = useState();

  const catgory = useCallback(() => {
    try {
      const cat = new FormData();
      cat.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/cate/movies`,
        data: cat,
        headers: {
          Accept: 'application/json',
          //   Authorization: `Bearer ${token}`,
        },
      };
      axios(config).then((response) => {
        setOptions(response?.data?.data[0]);

      });
    } catch (error) {
      console.log(error, 'category error');
    }
  }, [catvalue])

  useEffect(() => {
    catgory();
  }, [catgory]);

  // show details API
  const [apidetails, setApidetails] = useState([]);
  const [src, setSrc] = useState("");
  const ShowMovie = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/media/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setApidetails(response?.data?.media);
        setTitle(response?.data?.media?.title);
        setSubDesc(response?.data?.media?.subDes);
        setDesc(response?.data?.media?.des);
        setCatValue(response?.data?.media?.subCategory);
        setSrc(response?.data?.media?.url);
        console.log(response?.data?.media, 'movie api');
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [id, token])
  console.log(src, "show detail api");

  useEffect(() => {
    ShowMovie();
  }, [ShowMovie]);



  // show details API


  const [, setMovie] = useState();
  const [Error, setError] = useState();
  
  const Navigate = useNavigate();

  const editmovie = () => {
    setLoader(true);
    try {
      const moviesdata = new FormData();
      if (files != null) {
        moviesdata.append('media', files);
      }
      if (meta?.duration != null) {
        moviesdata.append('duration', parseInt(meta?.duration, 10));
        console.log(parseInt(meta?.duration, 10), 'meta.duration');
      }
      moviesdata.append('title', title);
      moviesdata.append('subDes', subDesc);
      moviesdata.append('des', desc);
      if (image != null) {
        moviesdata.append('thumbs', image);
      }
      moviesdata.append('type', 'episode');
      moviesdata.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/update/media/${id}`,
        data: moviesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setMovie(response.data);
          console.log(response, 'add movie =======');
          if (response?.data.status === true) {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Episode Edit successFully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate(`/dashboard/episode/${seasonNo}?season=${seasonId}`);
          } else {
            setLoader(false);
          }
        }).catch((error) => {
          setLoader(false);
          setError(error?.response?.data?.errors);
          console.log(error, ' Episode error');
          Swal.fire({
            title: 'Good job! ',
            text: error?.response?.data?.message,
            icon: 'error',
            button: 'Ok',
          });
        })
    } catch (error) {
      setLoader(false);
      console.log(error, ' Episode error');
      Swal.fire({
        title: 'Something went wrong ',
        text: error?.response?.data?.message,
        icon: 'error',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Edit Episode | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Edit Episode</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
           
            <FormLabel>Title</FormLabel>
            <TextField
              fullWidth
              id="fullWidth"
              sx={{ mb: 3 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <p className='text-danger'>{Error?.title ? Error?.title[0] : null}</p>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormLabel>Sub Description</FormLabel>
            <TextField
              id=""
              multiline
              rows={2}
              value={subDesc}
              placeholder="Enter episode Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <h5>Select Genres</h5>
            <CreatableSelect
              isClearable
              defaultValue={{ value: catvalue, label: catvalue }}
              options={option?.categories?.map((e) => ({ label: e.category, value: e.category }))}
              onChange={(e) => setCatValue(e.value)}
            />
            {/* {console.log(option.categories)} */}

            <h4 className=" my-3  text-center">Upload Series</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              {/* <h1>{name}</h1> */}
            </div>
            {name === "removed" || name === "" ?
              <video src={src} controls autoPlay loop height={235} style={{ marginTop: "20px" }}>
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
              </video>
              :
              null}

            <h4 className="my-3  text-center">Thumbnail</h4>
            <div className="col-md-12">
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg']}
              >
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  // write your building UI
                  <div className="upload__image-wrapper w-100">
                    <button
                      type="button"
                      className="uplaod"
                      style={isDragging ? { color: 'red', width: '100%' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;

                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" className="thumbnail_size" />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <button type='button' onClick={() => onImageRemove(index)} className="remove_btn">
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              {image ?
                null
                :
                <img src={apidetails.thumbs} alt="" />
              }
            </div>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={editmovie}>
            Update
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default EditEpisode