import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageUploading from 'react-images-uploading';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Addmovie() {
  const [setName] = useState('');
  const [files, setFiles] = useState();
  const [teaserfiles, setTeaserFiles] = useState();
  const [meta, setMeta] = useState();
  // specify upload params and url for your files
  // eslint-disable-next-line no-unused-vars
  const getUploadParams = ({ meta }) => ({ url: 'https://httpbin.org/post' });

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(status, 'status');
    setMeta(meta, 'meta');
    setFiles(file, 'file');
  };
   const teaserStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(status, 'status');
    setMeta(meta, 'meta');
    setTeaserFiles(file, 'file teaser');
  };

  console.log(teaserfiles, 'teaser')

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList, addUpdateIndex);
    setImage(imageList[0].file);
  };
  console.log(images, '...cover photo');
  console.log(image, '...cover photo');

  // Movie upload work
  const [File] = useState(null);
  console.log('images', File);

  const [loader, setLoader] = useState(false);

  const [option, setOptions] = useState([]);
  const [catvalue, setCatValue] = useState();
  const [mediaType] = useState();
  const [title, setTitle] = useState();
  const [subDesc, setSubDesc] = useState();
  const [desc, setDesc] = useState();
  console.log(catvalue, 'create value');
  console.log(mediaType, 'mediaType value');
  console.log(title, 'title value');
  console.log(subDesc, 'subDesc value');
  console.log(desc, 'desc value');

  const catgory = useCallback(() => {
    try {
      const cat = new FormData();
      cat.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/cate/movies`,
        data: cat,
        headers: {
          Accept: 'application/json',
          //   Authorization: `Bearer ${token}`,
        },
      };
      axios(config).then((response) => {
        setOptions(response?.data?.data[0]);
        console.log(response?.data?.data[0], 'category data');
      });
    } catch (error) {
      console.log(error, 'category error');
    }
  },[catvalue])

  useEffect(() => {
    catgory();
  }, [catgory]);



  const [setMovie] = useState();

  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  console.log(token, 'token');
  const addmovie = () => {
    setLoader(true);
    try {
      const moviesdata = new FormData();
      moviesdata.append('media', files);
      moviesdata.append('duration', parseInt(meta.duration, 10));
      console.log(parseInt(meta.duration, 10), 'meta.duration');
      moviesdata.append('title', title);
      moviesdata.append('subDes', subDesc);
      moviesdata.append('des', desc);
      moviesdata.append('thumbs', image);
      moviesdata.append('type', 'movies');
      moviesdata.append('category', catvalue);
      moviesdata.append('trailer', teaserfiles);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/add/media`,
        data: moviesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setMovie(response.data);
        console.log(response, 'add movie =======');
        if (response?.data.status === true) {
          setLoader(false);
          Swal.fire({
            title: 'Good job! ',
            text: 'Your Movie added success Fully',
            icon: 'success',
            button: 'Ok',
          });
          Navigate('/dashboard/movies');
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
      console.log(error, ' movie error');
      Swal.fire({
        title: 'Good job! ',
        text: error?.message,
        icon: 'success',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Movies | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Add Movie</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {/* <h6>Media Type</h6>
            <TextField
              fullWidth
              value="Movie"
              disabled
              id="fullWidth"
              sx={{ mb: 3 }}
              onChange={(e) => setMediaType(e.target.value)}
            /> */}
            <TextField
              fullWidth
              label="Title"
              id="fullWidth"
              sx={{ mb: 3 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label="Sub Description"
              multiline
              rows={2}
              placeholder="Enter Movie Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              placeholder="Enter Movie Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <h6>Select Genres</h6>
            <CreatableSelect
              isClearable
              options={option?.categories?.map((e) =>({label: e.category, value: e.category}))}
              onChange={(e) => setCatValue(e.value)}
            />
            {/* {console.log(option.categories)} */}

            <h4 className=" my-3  text-center">Upload Movie</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              {/* <h1> {name}</h1> */}
            </div>

            <h4 className=" my-3  text-center">Upload Trailer</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={teaserStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              {/* <h1> {name}</h1> */}
            </div>

            <h4 className="my-3  text-center">Thumbnail</h4>
            <div className="col-md-12">
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg']}
              >
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  // write your building UI
                  <div className="upload__image-wrapper w-100">
                    <button
                      type="button"
                      className="uplaod"
                      style={isDragging ? { color: 'red', width: '100%' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" className="thumbnail_size" />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <button type='button' onClick={() => onImageRemove(index)} className="remove_btn">
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={addmovie}>
            Add
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
