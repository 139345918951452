import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  TextField,
  TableRow,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Id', label: 'Id', alignRight: false },
  { id: 'thumbs', label: 'Thumbs', alignRight: false },
  { id: 'name', label: 'Title' },
  { id: 'subDes', label: 'Sub Description', alignRight: false },
  { id: 'action', label: 'Action', alignCenter: true },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log(array, 'abdullah ki gand maro');
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Series() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  // const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const [removeid, setRemoveid] = useState('');

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const token = localStorage.getItem('accessToken');

  const ShowSeries = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: 'https://sarealtvapi.developer-iu.xyz/api/admin/fetch/series',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false);
        setUSERLIST(response?.data?.series?.data);
        console.log(response?.data?.series?.data, 'series api');
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  }, [token])

  useEffect(() => {
    ShowSeries();
  }, [ShowSeries]);

  const addSeries = () => {
    navigate('/dashboard/addseries');
  };
  const detailview = (id) => {
    navigate(`/dashboard/season/${id}`);
    // localStorage.setItem('Seasonid');
  };

  // detach work

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    // assignMedia();
  };
  const handleClose = () => setOpen(false);

  // detach api work
  const [Detach, setDetach] = useState();
  const [assignmedia, setAssignmedia] = useState([]);

  const assignMedia = (removeid) => {
    alert(removeid)
    setLoader(true);
    try {
      const config = {
        method: 'POST',
        url: `http://sarealtvapi.developer-iu.xyz/api/assigned/packages/${removeid}/series`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setAssignmedia(response?.data?.seriesDetail?.packages);
        console.log(response?.data?.seriesDetail?.packages, 'assignmedia  data');
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
      console.log(error, 'assignmedia error');
    }
  };

  const detachMediaApi = (id) => {
    setLoader(true);
    try {
      const detachmediadata = new FormData();
      detachmediadata.append('id[]', id);
      detachmediadata.append('package_id', Detach);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/detach/series`,
        data: detachmediadata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setLoader(false);
          console.log(response, 'detachmedia  data');
          if (response?.data?.status === true) {
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Movie Edit successFully',
              icon: 'success',
              button: 'Ok',
            });
            navigate('/dashboard/series');
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error, 'assignmedia error');
          Swal.fire({
            title: 'OPPS! ',
            text: 'Your Movie Not Detach',
            icon: 'success',
            button: 'Ok',
          });
        });
    } catch (error) {
      console.log(error, 'assignmedia error');
    }
  };

  const deleteSeries = (seriesid) => {
    setLoader(true);
    try {
      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/remove/series/${seriesid}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setLoader(false);
          console.log(response, 'deleteseries  data');
          if (response?.data?.status === true) {
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Series Delete successFully',
              icon: 'success',
              button: 'Ok',
            });
            ShowSeries()

          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error, 'deleteseries error');
          Swal.fire({
            title: 'OPPS! ',
            text: error?.response?.data?.message,
            icon: 'danger',
            button: 'Ok',
          });
        });
    } catch (error) {
      setLoader(false);
      console.log(error, 'deleteseries error');
      Swal.fire({
        title: 'OPPS! ',
        text: error?.response?.data?.message,
        icon: 'error',
        button: 'Ok',
      });
    }
  };


  return (
    <>
      <Helmet>
        <title> Series | SAREALTV </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Series
          </Typography>

          <Button
            onClick={addSeries}
            variant="contained"
            sx={{ backgroundColor: 'red' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Series
          </Button>
        </Stack>

        <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, backgroundColor: 'white' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, thumbs, subDes } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell style={{ width: '10%' }}>
                          <Typography variant="subtitle2">{id}</Typography>
                        </TableCell>

                        <TableCell style={{ width: '20%', textAlign: 'center' }}>
                          <Avatar alt="" src={thumbs} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" style={{ width: '20%' }}>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ width: '20%' }}>
                          {subDes}
                        </TableCell>
                        <TableCell component="th" style={{ width: '10%', display: 'flex' }}>
                          <MenuItem
                            sx={{ color: 'error.main', mr: 4 }}
                            onClick={() => {
                              detailview(id);
                            }}
                          >
                            <Iconify icon={'ic:outline-remove-red-eye'} sx={{ mr: 2 }} />
                            season
                          </MenuItem>

                          <MenuItem sx={{ color: 'error.main' }}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} onClick={() => {
                              deleteSeries(id);
                            }} />
                          </MenuItem>

                          <MenuItem sx={{ color: 'error.main' }}>
                            <Button
                              onClick={() => {
                                handleOpen();
                                assignMedia(id);
                              }}
                              variant="contained"
                              sx={{ backgroundColor: 'red' }}
                              startIcon={<Iconify icon="eva:trash-2-outline" />}
                            >
                              Remove package
                            </Button>
                          </MenuItem>
                        </TableCell>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>


                            <TextField
                              id="outlined-select-currency"
                              select
                              fullWidth
                              sx={{ mb: 3 }}
                              label="Remove package from this media"
                              // defaultValue="Please Select Package Type"
                              onChange={(e) => setDetach(e.target.value)}
                            >
                              {assignmedia?.map((option) => (
                                <MenuItem key={option.name} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>


                            <Button
                              onClick={() => detachMediaApi(id)}
                              variant="contained"
                              sx={{ backgroundColor: 'red' }}
                              startIcon={<Iconify icon="eva:trash-2-outline" />}
                            >
                              Detach
                            </Button>
                          </Box>
                        </Modal>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {assignmedia.length === 0 ? (
            <>
              <p>No Package for remove</p>
            </>
          ) : (
            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              sx={{ mb: 3 }}
              label="Remove package from this media"
              // defaultValue="Please Select Package Type"
              onChange={(e) => setDetach(e.target.value)}
            >
              {assignmedia.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Button
            onClick={detachMediaApi}
            variant="contained"
            sx={{ backgroundColor: 'red' }}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            Detach
          </Button>
        </Box>
      </Modal> */}
    </>
  );
}
