import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect,useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import 'react-dropzone-uploader/dist/styles.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const currencies = [
  {
    value: 'Day',
    label: 'Day',
  },
  {
    value: 'Week',
    label: 'Week',
  },
  {
    value: 'Month',
    label: 'Month',
  },
  {
    value: 'Year',
    label: 'Year',
  },
  {
    value: 'Decade',
    label: '10 Years',
  },
  {
    value: 'Life Time',
    label: 'Life Time',
  },
];
const PackagePlan = [
  {
    value: 'Package-Plan',
    label: 'Rent / Buy',
  },
  {
    value: 'Subscription',
    label: 'Subscription',
  },
];

const Movietv = [
  {
    value: 'Movies',
    label: 'Movies',
  },
  {
    value: 'Tv Series',
    label: 'Tv Series',
  },
];

const CreatePackage = () => {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [Amount, setAmount] = useState(0);
  const [NOD, setNOD] = useState(0);
  const [Plan, setPlan] = useState('');
  const [loader, setLoader] = useState(false);
  const [GetAllMovie, setGetAllMovie] = useState('');
  const [GetAllSeries, setGetAllSeries] = useState('');
  const [Movie, setMovie] = useState([]);
  // const [Series, setSeries] = useState([]);
  const [Packagedetails, setPackagedetails] = useState();
  const [MovieTv, setMovieTv] = useState();
  const [Error, setError] = useState({});
  const [Desc, setDesc] = useState('');
  
 
  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  const ShowMovie = useCallback(() => {
    const config = {
      method: 'get',
      url: 'https://sarealtvapi.developer-iu.xyz/api/fetch/movies/all_categories',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setGetAllMovie(response?.data?.media?.data);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(token);
  },[token])

  useEffect(() => {
    ShowMovie();
  }, [ShowMovie]);

  // show tv Seires

  const ShowSeries = useCallback(() => {
    const config = {
      method: 'get',
      url: 'https://sarealtvapi.developer-iu.xyz/api/admin/fetch/series',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setGetAllSeries(response?.data?.series?.data);
        console.log(response?.data?.series?.data, 'series api');
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(token);
  },[token])

  useEffect(() => {
    ShowSeries();
  }, [ShowSeries]);

  // show tv Seires

  const PackageCreate = () => {
    setLoader(true);
    console.log(type, 'type');
    try {
      const packagedata = new FormData();
      packagedata.append('type', type);
      if (type === 'Decade' || type === 'Life Time') {
        packagedata.append('typeCount', 1);
      } else {
        packagedata.append('typeCount', NOD);
      }
      packagedata.append('amount', Amount);
      packagedata.append('des', Desc);
      packagedata.append('name', title);
      packagedata.append('packageType', Plan);

      const config = {
        method: 'POST',
        url: 'https://sarealtvapi.developer-iu.xyz/api/package',
        data: packagedata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setLoader(false);
          setPackagedetails(response?.data?.packageDetails);
          console.log(response, 'package data');
          if (
            response?.data?.status === true &&
            response?.data?.packageDetails?.packageType === 'Package-Plan' &&
            MovieTv === 'Movies'
          ) {
            AssignMedia(response?.data?.packageDetails?.id);
          } else if (
            response?.data?.status === true &&
            response?.data?.packageDetails?.packageType === 'Package-Plan' &&
            MovieTv === 'Tv Series'
          ) {
            AssignSeries(response?.data?.packageDetails?.id);
          } else if (
            response?.data?.status === true &&
            response?.data?.packageDetails?.packageType === 'Subscription'
          ) {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your  Package Created success Fully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard')
          } else {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your  Package Created success Fully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard')
          }
          // Navigate(`/dashboard/episode`);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
          // const { errors } = error;
          // const key = Object.keys(error.errors);
          // key.forEach((e) => {
          //   setError(errors[e][0]);
          // });
          setError(error?.response?.data?.errors);
        });
    } catch (error) {
      setLoader(false);
      console.log(error, 'Package error');
    }
  };

  const AssignMedia = (id) => {
    setLoader(true);
    try {
      const assignmedia = new FormData();

      Movie?.map(
        (e) => assignmedia.append('id[]', e?.value)
        // console.log(e?.value)
      );
      assignmedia.append('package_id', id);

      const config = {
        method: 'POST',
        url: 'https://sarealtvapi.developer-iu.xyz/api/assign/package',
        data: assignmedia,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setLoader(false);
        console.log(response, ' data');
        Swal.fire({
          title: 'Good job! ',
          text: 'Your  Package Created success Fully',
          icon: 'success',
          button: 'Ok',
        });
        Navigate('/dashboard')
      });
    } catch (error) {
      setLoader(false);
      console.log(error, 'Package error');
    }
  };

  const AssignSeries = (id) => {
    setLoader(true);
    try {
      const assignmedia = new FormData();

      Movie?.map(
        (e) => assignmedia.append('id[]', e?.value)
        // console.log(e?.value)
      );
      assignmedia.append('package_id', id);

      const config = {
        method: 'POST',
        url: 'http://sarealtvapi.developer-iu.xyz/api/assign/series',
        data: assignmedia,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setLoader(false);
        console.log(response, ' data');
        Swal.fire({
          title: 'Good job! ',
          text: 'Your  Package Created success Fully',
          icon: 'success',
          button: 'Ok',
        });
        Navigate('/dashboard')
      });
    } catch (error) {
      setLoader(false);
      console.log(error, 'Package error');
    }
  };

  console.log(Error, 'Error');
  console.log(GetAllMovie, 'GetAllMovie');
  console.log(Packagedetails, 'Packagedetails');
  console.log(
    Movie?.map((e) => e.value),
    'Movie'
  );
  return (
    <>
      <Helmet>
        <title> Create Package | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Create Package</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {/* create package fields */}
           

            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              sx={{ mb: 3 }}
              label="Please Select Package Type"
              // defaultValue="Please Select Package Type"
              onChange={(e) => setType(e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              <p className="text-danger">{Error?.packageType}</p>
            </TextField>
            <TextField
              fullWidth
              label="Title"
              id="fullWidth"
              placeholder="Enter Title"
              sx={{ mb: 2 }}
              onChange={(e) => setTitle(e.target.value)}
            />
            <p className="text-danger">{Error?.name}</p>
            {type === 'Day' || type === 'Week' || type === 'Month' || type === 'Year' ? (
              <>
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="No Of Days"
                  placeholder="Enter No Of Days"
                  // sx={{ mb: 2 }}
                  onChange={(e) => setNOD(e.target.value)}
                />
                <p className="text-danger">{Error?.typeCount}</p>
              </>
            ) : null}
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField
              id="outlined-multiline-static"
              label="Amount"
              rows={2}
              placeholder="Enter Package Amount"
              sx={{ mb: 3 }}
              onChange={(e) => setAmount(e.target.value)}
            />
              <TextField
              id="outlined-multiline-static"
              multiline
              label="Description"
              rows={2}
              placeholder="Enter Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              label="Please Select Package Plan"
              // defaultValue="Please Select Package Plan"
              onChange={(e) => setPlan(e.target.value) }
            >
              {PackagePlan?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <p className="text-danger">{Error?.type}</p>

            {Plan === "Package-Plan" ?
            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              sx={{ mb: 3 }}
              label="Create Package for..."
              // defaultValue="Create Package for..."
              onChange={(e) => setMovieTv(e.target.value)}
            >
              {Movietv.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            :null}
            {/* create package fields */}

            {Plan === 'Package-Plan' && MovieTv === 'Movies' ? (
              <Select
                closeMenuOnSelect={false}
                isMulti
                value={Movie}
                options={GetAllMovie?.map((e) => ({ label: e?.title, value: e?.id }))}
                onChange={(e) => setMovie(e?.map((i) => ({ label: i?.label, value: i?.value })))}
              />
            ) : Plan === 'Package-Plan' && MovieTv === 'Tv Series' ? (
              <Select
                closeMenuOnSelect={false}
                isMulti
                value={Movie}
                options={GetAllSeries?.map((e) => ({ label: e?.title, value: e?.id }))}
                onChange={(e) => setMovie(e?.map((i) => ({ label: i?.label, value: i?.value })))}
              />
            ) : null}
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={PackageCreate}>
            Add
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CreatePackage;
