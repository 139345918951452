import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'; 
// components;
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'name', alignCenter: true },
  { id: 'amount', label: 'amount', alignRight: false },
  { id: 'type', label: 'type', alignRight: false },
  // { id: 'type', label: 'type', alignRight: false },
  // { id: 'packageType', label: 'packageType', alignRight: false },

  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignCenter: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log(array, 'abdullah ki gand maro');
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Movies() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  // const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  
  const token = localStorage.getItem('accessToken');  
  const Show = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: 'https://sarealtvapi.developer-iu.xyz/api/rent/packages',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false);
        if(response.data.status){

          setUSERLIST(response?.data?.package);
        }
        console.log(response?.data?.package, 'movie api');
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  },[token])

  useEffect(() => {
    Show();
  }, [Show]);


  const edit = (id) => {
    navigate(`/dashboard/editrent/${id}`);
  };

  const  [setMovie] = useState();

  const delet = (id) => {
      setLoader(true);
      try {
        const config = {
          method: 'DELETE',
          url: `https://sarealtvapi.developer-iu.xyz/api/package/${id}`,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        };
        axios(config).then((response) => {
          setMovie(response.data);
          console.log(response, 'add movie =======');
          if (response?.data.status === true) {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: ' deleted successfully',
              icon: 'success',
              button: 'Ok',
            });
            Show();
          } else {
            setLoader(false);
          }
        });
      } catch (error) {
        setLoader(false);
        console.log(error, ' movie error');
        Swal.fire({
          title: 'Good job! ',
          text: error?.message,
          icon: 'success',
          button: 'Ok',
        });
      }
    };  

  return (
    <>
      <Helmet>
        <title> Subscription | SAREALTV </title>
      </Helmet>

      <Container>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
           Subscription
          </Typography>

          <Button
            onClick={addSubscriptions}
            variant="contained"
            sx={{ backgroundColor: 'red' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Subscription
          </Button>
        </Stack> */}

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, backgroundColor: 'white' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  // numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // console.log(row, 'abdullah nay gande marwa li');
                    const { id, name, type, amount } = row;

                    // const selectedUser = selected.indexOf(title) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        {/* <TableCell padding="checkbox" style={{ width: '10%' }}>
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event)} />
                        </TableCell> */}
                        <TableCell style={{ width: '10%' }}>
                          <Typography variant="subtitle2">{row.id}</Typography>
                        </TableCell>

                
                        {/* <TableCell component="th" scope="row" padding="none" style={{ width: '20%' }}>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {filterName}
                            </Typography>
                          </Stack>
                        </TableCell> */}

                        {/* <TableCell >{des}</TableCell> */}

                        <TableCell align="left" style={{ width: '30%' }}>
                          {name}
                        </TableCell>
                        <TableCell align="left" style={{ width: '30%' }}>
                          {amount}
                        </TableCell>
                        <TableCell align="left" style={{ width: '30%' }}>
                          {type}
                        </TableCell>

                     

                        
                    <TableCell align="left" component="th" style={{ width: '10%', display: 'flex' }}>
                      {/* <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label> */}
                      <MenuItem onClick={() => edit(id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                          <MenuItem sx={{ color: 'error.main' }}  onClick={() => delet(id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </MenuItem>
                    </TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                        {/* <TableCell align="right">
                        <Popover
                          open={Boolean(open)}
                          anchorEl={open}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                              },
                            },
                          }}
                        >
                        <MenuItem onClick={() => edit(id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            {row.id}
                          </MenuItem>

                        
                        </Popover>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
