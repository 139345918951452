import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Id', label: 'Id', alignRight: false },
  { id: 'thumbs', label: 'Thumbs', alignRight: false },
  { id: 'name', label: 'Title', alignCenter: true },
  { id: 'subDes', label: 'Sub Description', alignRight: false },
  { id: 'subcategory', label: 'Genres', alignRight: false },
  { id: 'episode', label: 'Episode', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  //   { id: 'status', label: 'Status', alignRight: false },
  //   { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log(array, 'abdullah ki gand maro');
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Series() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const seasonId = searchParams.get('season');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const token = localStorage.getItem('accessToken');

  const { seasonNo } = useParams();
  console.log(seasonNo);
  const seriesid = localStorage.getItem('seriesid');

  const ShowEpidose = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: `https://sarealtvapi.developer-iu.xyz/api/admin/fetch/season/${seriesid}/${seasonNo}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false);
        setUSERLIST(response?.data?.seasons?.data);
        console.log(response, 'ShowEpidose api');
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  }, [token, seasonNo, seriesid]);

  useEffect(() => {
    ShowEpidose();
  }, [ShowEpidose]);

  const addepisode = () => {
    navigate(`/dashboard/addepisode/${seasonNo}?season=${seasonId}`);
  };
  const edit = (id) => {
    navigate(`/dashboard/editepisode/${id}/${seasonNo}?season=${seasonId}`);
  };

  const [,setMovie] = useState();
  const delet = (id) => {
    setLoader(true);
    try {
      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/remove/media/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setMovie(response.data);
        console.log(response, 'add movie =======');
        if (response?.data.status === true) {
          setLoader(false);
          Swal.fire({
            title: 'Good job! ',
            text: 'Your Episode deleted successFully',
            icon: 'success',
            button: 'Ok',
          });
          window.location.reload();
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
      console.log(error, ' Trailer error');
      Swal.fire({
        title: 'Some Thing Went Wrong! ',
        text: error?.message,
        icon: 'danger',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Episode | SAREALTV </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Episodes
          </Typography>

          <Button
            onClick={addepisode}
            variant="contained"
            sx={{ backgroundColor: 'red' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Episode
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, backgroundColor: 'white' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, thumbs, subDes, subCategory, episode } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell style={{ width: '5%' }}>
                          <Typography variant="subtitle2">{id}</Typography>
                        </TableCell>

                        <TableCell style={{ width: '10%', textAlign: 'center' }}>
                          <Avatar alt="" src={thumbs} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" style={{ width: '20%' }}>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" style={{ width: '20%' }}>
                          {subDes}
                        </TableCell>
                        <TableCell align="left" style={{ width: '20%' }}>
                          {subCategory}
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>{episode}</TableCell>
                        <TableCell style={{ width: '30%', display: 'flex' }}>
                          <MenuItem sx={{ color: 'error.main' }} onClick={() => edit(id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>

                          <MenuItem sx={{ color: 'error.main' }} onClick={() => delet(id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{}} />
                          </MenuItem>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

     
    </>
  );
}
