import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect,useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import ImageUploading from 'react-images-uploading';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel } from '@mui/material';

const EditMovies = () => {
  const [name, setName] = useState('');
  const [files, setFiles] = useState();
  const [Detach, setDetach] = useState([]);
  const [assignmedia, setAssignmedia] = useState([]);
  const [meta, setMeta] = useState();

  // detach api work
  const token = localStorage.getItem('accessToken');
  console.log(token, 'token');
  const { id } = useParams();

  const assignMedia = useCallback(() => {
    setLoader(true);
    try {
      const config = {
        method: 'POST',
        url: `http://sarealtvapi.developer-iu.xyz/api/assigned/packages/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config).then((response) => {
        setAssignmedia(response?.data?.mediaDetail?.packages);
        console.log(response?.data?.mediaDetail?.packages, 'assignmedia  data');
      });
    } catch (error) {
      console.log(error, 'assignmedia error');
    }
  },[id,token])

  useEffect(() => {
    assignMedia();
  }, [assignMedia]);

  const detachMediaApi = () => {
    setLoader(true);
    try {
      const detachmediadata = new FormData();
      detachmediadata.append('id[]', id);
      detachmediadata.append('package_id', Detach);

      const config = {
        method: 'POST',
        url: `http://sarealtvapi.developer-iu.xyz/api/detach/package`,
        data: detachmediadata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setAssignmedia(response);
          console.log(response, 'detachmedia  data');
          if (response?.data?.status === true) {
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Movie Edit successFully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard/movies');
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error, 'assignmedia error');
          Swal.fire({
            title: 'OPPS! ',
            text: 'Your Movie Not Detach',
            icon: 'success',
            button: 'Ok',
          });
        });
    } catch (error) {
      console.log(error, 'assignmedia error');
    }
  };

  console.log(assignmedia.id, 'Detach value');

  // detach api work

  // specify upload params and url for your files
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(status, 'status');
    setMeta(meta, 'meta');
    setFiles(file, 'file');
  };

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList, addUpdateIndex);
    setImage(imageList[0].file);
  };
  console.log(images, '...cover photo');
  console.log(image, '...cover photo');

  // Movie upload work

  const [loader, setLoader] = useState(false);
  const [option, setOptions] = useState([]);
  const [catvalue, setCatValue] = useState();
  const [mediaType] = useState();
  const [title, setTitle] = useState();
  const [subDesc, setSubDesc] = useState();
  const [desc, setDesc] = useState();
  console.log(catvalue, 'create value');
  console.log(mediaType, 'mediaType value');
  console.log(title, 'title value');
  console.log(subDesc, 'subDesc value');
  console.log(desc, 'desc value');

  const catgory = useCallback(() => {
    try {
      const cat = new FormData();
      cat.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/cate/movies`,
        data: cat,
        headers: {
          Accept: 'application/json',
          //   Authorization: `Bearer ${token}`,
        },
      };
      axios(config).then((response) => {
        setOptions(response?.data?.data[0]);
        console.log(response?.data?.data[0], 'category data');
      });
    } catch (error) {
      console.log(error, 'category error');
    }
  },[catvalue])

  useEffect(() => {
    catgory();
  }, [catgory]);

  console.log(catvalue);
  // show details API
  const [apidetails, setApidetails] = useState([]);
  const [src, setSrc] = useState('');
  
  const ShowMovie = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: `http://sarealtvapi.developer-iu.xyz/api/admin/get/media/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        setApidetails(response?.data?.media);
        setTitle(response?.data?.media?.title);
        setSubDesc(response?.data?.media?.subDes);
        setDesc(response?.data?.media?.des);
        setCatValue(response?.data?.media?.subCategory);
        setSrc(response?.data?.media?.url);
        console.log(response, 'movie api');
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  },[id,token])
  console.log(catvalue, 'show detail api');

  useEffect(() => {
    ShowMovie();
  }, [ShowMovie]);

  // show details API

  const [setMovie] = useState();
  const Navigate = useNavigate();

  console.log(id, 'edit');

  const editmovie = () => {
    setLoader(true);
    try {
      const moviesdata = new FormData();
      if (files != null) {
        moviesdata.append('media', files);
      }
      if (meta?.duration != null) {
        moviesdata.append('duration', parseInt(meta?.duration, 10));
        console.log(parseInt(meta?.duration, 10), 'meta.duration');
      }
      moviesdata.append('title', title);
      moviesdata.append('subDes', subDesc);
      moviesdata.append('des', desc);
      if (image != null) {
        moviesdata.append('thumbs', image);
      }
      moviesdata.append('type', 'movies');
      moviesdata.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `http://sarealtvapi.developer-iu.xyz/api/admin/update/media/${id}`,
        data: moviesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      axios(config)
        .then((response) => {
          setMovie(response.data);
          console.log(response, 'add movie =======');
          if (response?.data.status === true && assignmedia.length !== 0) {
            setLoader(false);
            detachMediaApi();
          }
          else {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Movie Edit successFully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard/movies');
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error, 'error edit');
          Swal.fire({
            title: 'OPPS! ',
            text: error?.message,
            icon: 'error',
            button: 'Ok',
          });
        });
    } catch (error) {
      setLoader(false);
      console.log(error, ' movie error');
      Swal.fire({
        title: 'OPPS! ',
        text: error?.message,
        icon: 'error',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Edit Movie | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Edit Movie</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {/* <h6>Media Type</h6>
            <TextField
              fullWidth
              value="Movie"
              disabled
              id="fullWidth"
              sx={{ mb: 3 }}
              onChange={(e) => setMediaType(e.target.value)}
            /> */}
            <FormLabel>Title</FormLabel>
            <TextField
              fullWidth
              id="fullWidth"
              sx={{ mb: 3 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormLabel>Sub Description</FormLabel>
            <TextField
              id=""
              multiline
              rows={2}
              value={subDesc}
              placeholder="Enter Movie Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <FormLabel>Description</FormLabel>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={desc}
              placeholder="Enter Movie Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <h4>Select Genres</h4>
            <CreatableSelect
              isClearable
              defaultValue={{ value: catvalue, label: catvalue }}
              options={option?.categories?.map((e) => ({ label: e.category, value: e.category }))}
              onChange={(e) => setCatValue(e.value)}
            />
            {/* {console.log(option.categories)} */}

            <h4 className=" my-3  text-center">Upload Movie</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              {/* <h1>{name}</h1> */}
            </div>
            {name === 'removed' || name === '' ? (
              <video src={src} controls autoPlay loop height={235} style={{ marginTop: '20px' }}>
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
              </video>
            ) : null}

            <h4 className="my-3  text-center">Thumbnail</h4>
            <div className="col-md-12">
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg']}
              >
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  // write your building UI
                  <div className="upload__image-wrapper w-100">
                    <button
                      type="button"
                      className="uplaod"
                      style={isDragging ? { color: 'red', width: '100%' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" className="thumbnail_size" />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <button type='button' onClick={() => onImageRemove(index)} className="remove_btn">
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              {image ? null : <img src={apidetails.thumbs} alt="" />}
            </div>

            {assignmedia.length === 0 ? (
              <>
                <p>No Package for remove</p>
              </>
            ) : (
              <TextField
                id="outlined-select-currency"
                select
                fullWidth
                sx={{ mb: 3 }}
                label="Remove package from this media"
                // defaultValue="Please Select Package Type"
                onChange={(e) => setDetach(e.target.value)}
              >
                {assignmedia.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={editmovie}>
            Update
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default EditMovies;
