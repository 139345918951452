// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Trailer',
    path: '/dashboard/Trailer',
    icon: icon('ic_trailer'),
  },
  {
    title: 'Movies',
    path: '/dashboard/movies',
    icon: icon('ic_movies'),
  },
  {
    title: 'Tv Shows',
    path: '/dashboard/series',
    icon: icon('ic_series'),
  },
  {
    title: 'Create Packages',
    path: '/dashboard/createpackage',
    icon: icon('ic_package'),
  },
  // {
  //   title: 'Packages',
  //   path: '/dashboard/package',
  //   icon: icon('ic_package'),
  // },
  {
    title: 'Subscription',
    path: '/dashboard/subscriptions',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Buy',
    path: '/dashboard/buy',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Rent',
    path: '/dashboard/rent',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;
