import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddTrailer() {
  const [name, setName] = useState('');
  const [files, setFiles] = useState();
  const [meta, setMeta] = useState();
  // specify upload params and url for your files
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(meta , status ,  'status');
    setMeta(meta);
    setFiles(file);
  };

  // Movie upload work
  const [loader, setLoader] = useState(false);

  const [option, setOptions] = useState([]);
  const [catvalue, setCatValue] = useState();
  const [title, setTitle] = useState();
  const [subDesc, setSubDesc] = useState();
  const [desc, setDesc] = useState();

  const catgory = useCallback(() => {
    try {
      const cat = new FormData();
      cat.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/cate/trailers`,
        data: cat,
        headers: {
          Accept: 'application/json',
          //   Authorization: `Bearer ${token}`,
        },
      };
      axios(config).then((response) => {
        setOptions(response?.data?.data[0]);
        console.log(response?.data?.data[0], 'category data');
      }).catch((error) => {
        console.log(error, "category api error")

      })
    } catch (error) {
      console.log(error, 'category error');
    }
  }, [catvalue])

  useEffect(() => {
    catgory();
  }, [catgory]);

  const [, setMovie] = useState();

  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  const addmovie = () => {
    setLoader(true);
  
    try {
      if (!title || !files || !subDesc || !desc || !catvalue) {
        setLoader(false);
        Swal.fire({
          title: 'Error',
          text: 'Please fill in all required fields.',
          icon: 'error',
          button: 'Ok',
        });
      } else {
        const moviesdata = new FormData();
        moviesdata.append('media', files);
        moviesdata.append('duration', Math.round(meta.duration));
        moviesdata.append('title', title);
        moviesdata.append('subDes', subDesc);
        moviesdata.append('des', desc);
        moviesdata.append('thumbs', '');
        moviesdata.append('type', 'trailers');
        moviesdata.append('category', catvalue);
  
        const config = {
          method: 'POST',
          url: `https://sarealtvapi.developer-iu.xyz/api/admin/add/media`,
          data: moviesdata,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        };
  
        axios(config)
          .then((response) => {
            setLoader(false);
            setMovie(response.data);
            console.log(response, 'add trailer =======');
            if (response?.data.status === true) {
              Swal.fire({
                title: 'Good job! ',
                text: 'Your trailer added successfully',
                icon: 'success',
                button: 'Ok',
              }).then(() => {
                Navigate('/dashboard/Trailer');
              });
            }
          })
          .catch((error) => {
            setLoader(false);
            if (error.response) {
              const errorMessage = error.response.data.message || 'An unexpected error occurred';
              Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                button: 'Ok',
              });
            }
          });
      }
    } catch (error) {
      setLoader(false);
      console.log(error, ' Trailer error');
      Swal.fire({
        title: error.response?.data.message || 'Error',
        text: error?.message,
        icon: 'error',
        button: 'Ok',
      });
    }
  };
  
  

 
  return (
    <>
      <Helmet>
        <title> Add Trailer | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Add Trailer</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <TextField
              fullWidth
              label="Title"
              id="fullWidth"
              sx={{ mb: 3 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label="Sub Description"
              multiline
              rows={2}
              placeholder="Enter Trailer Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              placeholder="Enter Trailer Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <h5>Select Genres</h5>
            <CreatableSelect
              isClearable
              options={option?.categories?.map((e) => ({ label: e.category, value: e.category }))}
              onChange={(e) => setCatValue(e.value)}
            />
            {/* {console.log(option.categories)} */}

            <h4 className=" my-3  text-center">Upload Trailer</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept="image/*,audio/*,video/*"
              />
              <h1>{name === 'headers_received' ? 'Upload Trailer' : name}</h1>
            </div>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={addmovie}>
            Add
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
