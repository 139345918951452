import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageUploading from 'react-images-uploading';
import 'react-dropzone-uploader/dist/styles.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import Dropzone from 'react-dropzone-uploader';
import { useNavigate } from 'react-router-dom';



export default function AddSeason() {

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();
  const [Trailername,setTrailerName] = useState('');
  const [teaserfiles, setTeaserFiles] = useState();
  // const [meta, setMeta] = useState();

  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });
  const teaserStatus = ({ meta, file }) => {
    setTrailerName(meta.status);
    // setMeta(meta, 'meta');
    setTeaserFiles(file, 'file teaser');
  };


  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList, addUpdateIndex);
    setImage(imageList[0].file);
  };


  // Movie upload work
  const [loader, setLoader] = useState(false);




  const [title, setTitle] = useState();
  const [subDesc, setSubDesc] = useState();
  const [desc, setDesc] = useState();
  const [season, setSeason] = useState();
  const [Error, setError] = useState();




  const [, setSeries] = useState();
  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  console.log(token, 'token');
  const seriesid = localStorage.getItem('seriesid');

  const addSeason = () => {
    setLoader(true);
    try {
      const seriesdata = new FormData();
      seriesdata.append('series_id', seriesid);
      seriesdata.append('title', title);
      seriesdata.append('subDes', subDesc);
      seriesdata.append('des', desc);
      seriesdata.append('thumbs', image);
      seriesdata.append('season', Number(season));
      seriesdata.append('trailer', teaserfiles);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/add/season`,
        data: seriesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setSeries(response.data);
          console.log(response, 'add season =======');
          if (response?.data.status === true) {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your season added success Fully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate(`/dashboard/season/${seriesid}`);
          } else {
            setLoader(false);
          }
        }).catch((error) => {
          setLoader(false);
          console.log(error, ' season error');
          setError(error?.response?.data?.errors);
          Swal.fire({
            title: 'Something went wrong! ',
            text: error?.response?.data?.message,
            icon: 'danger',
            button: 'Ok',
          });
        })
    } catch (error) {
      setLoader(false);
      console.log(error, ' season error');
      Swal.fire({
        title: 'Something went wrong! ',
        text: error?.response?.data?.message,
        icon: 'danger',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Season | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Add Season</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <TextField
              fullWidth
              label="Title"
              id="fullWidth"
              sx={{ mb: 3 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <p className='text-danger'>{Error?.title ? Error?.title[0] : null}</p>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label="Sub Description"
              multiline
              rows={2}
              placeholder="Enter Season Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <p className='text-danger'>{Error?.subDes ? Error?.subDes[0] : null}</p>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={2}
              placeholder="Enter Season Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <p className='text-danger'>{Error?.des ? Error?.des[0] : null}</p>
            <TextField
              fullWidth
              id="fullWidth"
              label="Season *"
              placeholder="Enter Season NO"
              sx={{ mb: 3 }}
              onChange={(e) => setSeason(e.target.value)}
            />
            <p className='text-danger'>{Error?.season ? Error?.season[0] : null}</p>
                 
            <h4 className=" my-3  text-center">Upload Trailer</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={teaserStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              <h1>{Trailername === 'headers_received' ? 'Upload Trailer' : Trailername}</h1>
            </div>

            <h4 className="my-3  text-center">Thumbnail *</h4>
            <div className="col-md-12">
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg']}
              >
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  // write your building UI
                  <div className="upload__image-wrapper w-100">
                    <button
                      type="button"
                      className="uplaod"
                      style={isDragging ? { color: 'red', width: '100%' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" className="thumbnail_size" />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <button type='button' onClick={() => onImageRemove(index)} className="remove_btn">
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            <p className='text-danger'>{Error?.thumbs ? Error?.thumbs[0] : null}</p>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={addSeason}>
            Add
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
