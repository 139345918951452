import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-dropzone-uploader/dist/styles.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel } from '@mui/material';

const EditPackages = () => {
  const [loader, setLoader] = useState(false);

  const [title, setTitle] = useState();
  const [amount, setAmount] = useState();

  // show details AP

  const ShowPackage = useCallback(() => {
    setLoader(true);
    const config = {
      method: 'get',
      url: `http://sarealtvapi.developer-iu.xyz/api/package/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, 'gett all data');
        if (response.data.status) {
          setTitle(response?.data?.package?.name);
          setAmount(response?.data?.package?.amount);
        }
        console.log(response, 'Package api');
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ShowPackage();
  }, [ShowPackage]);

  // show details API

  const [setPackage] = useState();
  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  console.log(token, 'token');

  const { id } = useParams();
  console.log(id, 'edit');
  const editPackage = () => {
    setLoader(true);
    try {
      const Packagesdata = new FormData();
      Packagesdata.append('title', title);
      Packagesdata.append('amount', amount);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/package/${id}`,
        data: Packagesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      axios(config).then((response) => {
        setPackage(response.data);
        console.log(response, 'add Package =======');
        if (response?.data.status === true) {
          setLoader(false);
          Swal.fire({
            title: 'Good job! ',
            text: 'Your Package Edit successFully',
            icon: 'success',
            button: 'Ok',
          });
          Navigate('/dashboard/subscriptions');
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
      console.log(error, ' Package error');
      Swal.fire({
        title: 'Good job! ',
        text: error?.message,
        icon: 'success',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Edit Package | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Edit Package</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <FormLabel>Title</FormLabel>
            <TextField
              fullWidth
              id="fullWidth"
              sx={{ mb: 3 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormLabel>amount</FormLabel>
            <TextField
              id=""
              multiline
              rows={2}
              value={amount}
              placeholder="Enter Package Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={editPackage}>
            Update
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default EditPackages;
