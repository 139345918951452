import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Movies from './pages/Movies';
import DashboardAppPage from './pages/DashboardAppPage';
import Addmovie from './pages/Addmovie';
import Trailer from './pages/Trailer';
import AddTrailer from './pages/AddTrailer';
import Series from './pages/Series';
import AddSeries from './pages/AddSeries';
import Season from './pages/Season';
import AddSeason from './pages/AddSeason';
import Episode from './pages/Episode';
import AddEpisode from './pages/AddEpisode';
import EditMovies from './pages/EditMovies';
import EditTrailers from './pages/EditTrailers';
import EditEpisode from './pages/EditEpisode';
import CreatePackage from './pages/Package/CreatePackage';
import Subscription from './pages/Subscriptions/Subscription';
import EditSubscription from './pages/Subscriptions/EditSubscription';
import CreateSubscription from './pages/Subscriptions/AddSubscription';
import Rent from './pages/rent/rent';
import Editrent from './pages/rent/Editrent';
import Createrent from './pages/rent/Addrent';
import Buy from './pages/buy/buy';
import Editbuy from './pages/buy/Editbuy';
import Createbuy from './pages/buy/Addbuy';
import ViewPackage from './pages/Package/ViewPackage';


// ----------------------------------------------------------------------
export default function Router() {

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'Trailer', element: <Trailer /> },
        { path: 'AddTrailer', element: <AddTrailer /> },
        { path: 'edittrailer/:id', element: <EditTrailers /> },
        { path: 'Movies', element: <Movies /> },
        { path: 'addMovie', element: <Addmovie /> },
        { path: 'editmovies/:id', element: <EditMovies /> },
        { path: 'series', element: <Series /> },
        { path: 'addseries', element: <AddSeries /> },
        { path: 'season/:seriesid', element: <Season /> },
        { path: 'addseason', element: <AddSeason /> },
        { path: 'episode/:seasonNo', element: <Episode /> },
        { path: 'addepisode/:seasonNo', element: <AddEpisode /> },
        { path: 'editepisode/:id/:seasonNo', element: <EditEpisode /> },
        { path: 'createpackage', element: <CreatePackage /> },
        { path: 'package', element: <ViewPackage /> },
        { path: 'subscriptions', element: <Subscription /> },
        { path: 'editsubscriptions/:id', element: <EditSubscription /> },
        { path: 'createsubscriptions', element: <CreateSubscription /> },
        { path: 'buy', element: <Buy /> },
        { path: 'editbuy/:id', element: <Editbuy /> },
        { path: 'createbuy', element: <Createbuy /> },
        { path: 'rent', element: <Rent /> },
        { path: 'editrent/:id', element: <Editrent /> },
        { path: 'createrent', element: <Createrent /> },
      ],
    },
 
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
