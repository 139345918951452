import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment,TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import axios from 'axios';
import Swal from 'sweetalert2';
import Iconify from '../../../components/iconify';
// import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

export default function LoginForm() {

  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  const Navigate = useNavigate();
  const landlogin = () => {
    setLoader(true);
    try {
      const login = new FormData();
      login.append("email", email);
      login.append("password", password);
     
      axios
     .post("https://sarealtvapi.developer-iu.xyz/api/admin/login", login).then((response)=>{
       console.log(response, "working");
      //  setToken(response?.data?.accessToken)
       const status = (response?.data?.status)
       console.log(response?.data?.accessToken, "========================")
       localStorage.setItem("accessToken", JSON.stringify(response?.data?.accessToken));
       setLoader(false)
      if (status === true) {
        Swal.fire({
          title: "Good job!",
          text: "Admin Login SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        Navigate("/dashboard/app");
        setLoader(false)
      } else {
        setLoader(false)
        Swal.fire({
          title: 'OPPS! ',
          text: 'Invalid Credentials',
          icon: 'error',
          button: 'Ok',
        });
      }
    }).catch(()=>{
      setLoader(false)
      Swal.fire({
        title: 'OPPS! ',
        text: 'Invalid Credentials',
        icon: 'error',
        button: 'Ok',
      });
    })
    } catch (error) {
      setLoader(false)
      console.log(error?.message);
      Swal.fire({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" color="error"  onChange={(e)=>setEmail(e.target.value)} sx={{ input: { color: 'red' }  }} />

        <TextField
          name="password"
          label="password" color="error"
          onChange={((e)=>setPassword(e.target.value))}
          sx={{ input: { color: 'red' } }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center">
        <Checkbox name="remember" label="Remember me" />
        <Typography >Agree & Continue</Typography>
        </Stack>
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        loading={loader}
        loadingPosition="start"
        fullWidth
        size="large"
        type="submit"
        variant="outlined"
        color="error"
        // sx={{backgroundColor: "red", color: "white"}}
        onClick={landlogin}
      >
        Login
      </LoadingButton>
    </>
  );
}
