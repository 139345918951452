import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel } from '@mui/material';

const EditTrailers = () => {
  const [name, setName] = useState('');
  const [files, setFiles] = useState();
  const [meta, setMeta] = useState();
  // specify upload params and url for your files
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setName(meta.status);
    console.log(status, 'status');
    setMeta(meta, 'meta');
    setFiles(file, 'file');
  };

  const [loader, setLoader] = useState(false);

  const [option, setOptions] = useState([]);
  const [catvalue, setCatValue] = useState();
  const [title, setTitle] = useState("");
  const [subDesc, setSubDesc] = useState("");
  const [desc, setDesc] = useState();

  const catgory = useCallback(() => {
    try {
      const cat = new FormData();
      cat.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/cate/movies`,
        data: cat,
        headers: {
          Accept: 'application/json',
          //   Authorization: `Bearer ${token}`,
        },
      };
      axios(config).then((response) => {
        setOptions(response?.data?.data[0]);
        console.log(response?.data?.data[0], 'category data');
      });
    } catch (error) {
      console.log(error, 'category error');
    }
  }, [catvalue]);

  useEffect(() => {
    catgory();
  }, [catgory]);

  // show details API
  const [, setApidetails] = useState({});
  const [src, setSrc] = useState('');

  const ShowMovie = useCallback(() => {
    try {

      setLoader(true);
      const config = {
        method: 'get',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/get/media/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response?.data?.media, 'movie api');
          setApidetails(response?.data?.media);
          setTitle(response?.data?.media?.title);
          setSubDesc(response?.data?.media?.subDes);
          setDesc(response?.data?.media?.des);
          setCatValue(response?.data?.media?.subCategory);
          setSrc(response?.data?.media?.url);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error, "show data api");
        });
    } catch (error) {
      console.log(error, "show data api")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ShowMovie();
  }, [ShowMovie]);

  // show details API

  const [, setMovie] = useState();
  const Navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  const { id } = useParams();
  const editmovie = () => {
    setLoader(true);
    try {
      const moviesdata = new FormData();
      if (files != null) {
        moviesdata.append('media', files);
      }
      if (meta?.duration != null) {
        moviesdata.append('duration', parseInt(meta?.duration, 10));
        console.log(parseInt(meta?.duration, 10), 'meta.duration');
      }
      moviesdata.append('title', title);
      moviesdata.append('subDes', subDesc);
      moviesdata.append('des', desc);
      moviesdata.append('type', 'trailers');
      moviesdata.append('category', catvalue);

      const config = {
        method: 'POST',
        url: `https://sarealtvapi.developer-iu.xyz/api/admin/update/media/${id}`,
        data: moviesdata,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      };
      axios(config)
        .then((response) => {
          setMovie(response.data);
          console.log(response, 'add movie =======');
          if (response?.data.status === true) {
            setLoader(false);
            Swal.fire({
              title: 'Good job! ',
              text: 'Your Trailer Edit successFully',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard/Trailer');
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
          Swal.fire({
            title: error.response.data.message,
            text: error?.message,
            icon: 'danger',
            button: 'Ok',
          });
        });
    } catch (error) {
      setLoader(false);
      console.log(error, ' Trailer error');
      Swal.fire({
        title: error.response.data.message,
        text: error?.message,
        icon: 'danger',
        button: 'Ok',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Edit Movie | SAREALTV </title>
      </Helmet>
      <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card style={{ width: '50%', margin: 'auto' }}>
        <CardContent>
          <h1 color="text.secondary">Edit Trailer</h1>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {/* <h6>Media Type</h6>
          <TextField
            fullWidth
            value="Movie"
            disabled
            id="fullWidth"
            sx={{ mb: 3 }}
            onChange={(e) => setMediaType(e.target.value)}
          /> */}
            <FormLabel>Title</FormLabel>
            <TextField
              fullWidth
              id="fullWidth"
              sx={{ mb: 3 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormLabel>Sub Description</FormLabel>
            <TextField
              id=""
              multiline
              rows={2}
              value={subDesc}
              placeholder="Enter Trailer Sub Description"
              sx={{ mb: 3 }}
              onChange={(e) => setSubDesc(e.target.value)}
            />
            <FormLabel>Description</FormLabel>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={desc}
              placeholder="Enter Trailer Description"
              sx={{ mb: 3 }}
              onChange={(e) => setDesc(e.target.value)}
            />
            <h5>Select Genres</h5>
            <CreatableSelect
              isClearable
              defaultValue={{ value: catvalue, label: catvalue }}
              options={option?.categories?.map((e) => ({ label: e.category, value: e.category }))}
              onChange={(e) => setCatValue(e.value)}
            />
            {/* {console.log(option.categories)} */}

            <h4 className=" my-3  text-center">Upload Trailer</h4>
            <div className="form-group col-md-12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmit}
                accept="video/*"
              />
              <p style={{ textAlign: 'center' }}>{name}</p>
            </div>
            {name === 'removed' || name === '' ? (
              <video src={src} controls autoPlay loop height={235} style={{ marginTop: '20px' }}>
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
              </video>

            ) : null}
          </Box>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" sx={{ backgroundColor: 'red', my: 2 }} onClick={editmovie}>
            Update
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default EditTrailers;
